@import '../../styles/grid';

.loginForm {
  margin: 40px auto 0 auto;
  width: 100%;

  @media screen and (min-width: $md) {
    margin-top: 90px;
    width: 70%;
  }

  @media screen and (min-width: $lg) {
    width: 60%;
  }

  label {
    margin-bottom: 12px;
  }

  label + div {
    margin-bottom: 16px;
  }

  .loginHeaderText {
    color: #fff;
    display: inline-block;
    font-size: 38px;
    font-weight: bold;
    margin-bottom: 17px;
  }
}
