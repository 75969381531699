@import '../../styles/grid';

.loginPage {
  display: flex;
  flex-direction: column;

  @media screen and (min-width: $lg) {
    flex-direction: row;
  }
}

.leftLoginPage {
  background-color: #33558b;

  @media screen and (min-width: $lg) {
    background-position: center center;
    background-repeat: no-repeat;
    background-image: url('../../../public/images/bg.png');
    min-height: 100vh;
    flex: 1 1 0;
    width: 0;
  }

  @media screen and (min-width: $xl) {
    background-position: 0 0;
  }
}

.rightLoginPage {
  background-color: #33558b;
  padding: 0 5%;

  @media screen and (min-width: $lg) {
    flex: 1 1 0;
    padding: 0;
    width: 0;
  }

  .rightLoginPageWrapper {
    min-height: calc(100vh - 103px);
    width: 100%;

    @media screen and (min-width: $lg) {
      padding-top: 136px;
    }

    h1,
    span.text,
    a {
      color: #fff;
    }

    a {
      text-decoration: underline;
    }

    h1,
    span.text {
      display: none;
      text-align: center;

      @media screen and (min-width: $lg) {
        display: block;
      }
    }
  }

  .ant-form-item .ant-form-item-label > label {
    color: #fff;
  }
}

.loginHeader {
  .logo {
    display: block;
    margin: 40px auto;
    max-width: 280px;

    @media screen and (min-width: $lg) {
      margin-left: 10%;
      margin-top: 80px;
    }
  }
}

footer {
  color: #fff;
  display: flex;
  justify-content: flex-end;
  padding-bottom: 17px;

  @media screen and (min-width: $lg) {
    padding: 17px 50px;
  }

  p {
    font-size: 14px;
    margin: 0;
    text-align: right;
  }
}

.wrapper {
  margin: 0 auto;
  width: 100%;

  @media screen and (min-width: $md) {
    width: $md;
  }

  @media screen and (min-width: $lg) {
    width: $lg;
  }

  @media screen and (min-width: $xl) {
    width: $xl;
  }

  @media screen and (min-width: $xxl) {
    width: $xxl;
  }
}
