@import '../../styles/grid';

@media screen and (min-width: $lg) {
  .button svg {
    margin-right: 6px;
  }
}

.tab {
  padding: 0 16px;

  svg {
    margin-right: 6px;
  }
}
