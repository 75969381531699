.ant-menu-dark,
.ant-menu-dark > .ant-menu {
  background-color: #33558b;
  svg {
    fill: #fff;
  }
  a {
    color: inherit;
  }
}

.ant-menu-dark.ant-menu-inline .ant-menu-sub.ant-menu-inline,
.ant-menu-dark > .ant-menu.ant-menu-inline .ant-menu-sub.ant-menu-inline {
  background-color: #33558b;
}

.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title,
.ant-menu-dark > .ant-menu .ant-menu-submenu-selected > .ant-menu-submenu-title {
  color: #1677ff;

  svg {
    fill: #1677ff;
  }
}

.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-item-icon {
  &.forkliftMenuIcon {
    margin-left: -4px;
  }
}
