.page-404 {
  margin: 33px 0 30px 0;
  text-align: center;
  width: 100%;

  h2 {
    margin: 20px 0 10px 0;
  }

  img {
    display: block;
    margin: 0 auto;
  }

  a {
    margin-top: 20px;
  }
}
