.modalContent {
  bottom: inherit !important;
  left: inherit !important;
  height: 100vh;

  .MuiPaper-root {
    margin: 0;
    border-bottom-right-radius: inherit;
    border-top-right-radius: inherit;
    border-top: none;
    height: 100%;
    min-width: 320px;
    max-height: 100vh;
    width: 1000px;
  }
}

.wrapperButtons {
  margin-top: 30px;

  button[type='submit'] {
    margin-left: 20px;
  }
}
