$xs: 575px;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1400px;

$columns: 12;
$column-base-width: calc(100% / $columns);
$gutter: 12px;
