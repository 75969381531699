.informMessage {
  display: flex;
  flex-direction: column;
  justify-content: center;

  div {
    display: flex;

    .iconPositive {
      font-size: 4rem;
      margin-bottom: 20px;
    }

    .link {
      margin-top: 20px;
    }
  }
}
