.finalPrice {
  font-weight: bold;
  margin-bottom: 20px;
}

.borderColumn {
  border-left: 1px solid rgba(5, 5, 5, 0.06);
  padding-left: 20px;
  padding-right: 5px;
}

.paddingColumnRight {
  padding-right: 10px;
}

.addProduct {
  margin-top: 15px;
}
