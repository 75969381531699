*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  -webkit-text-size-adjust: 100%;
}

body {
  font-family: 'Roboto', sans-serif;
  background-color: #f7f7f7;
  margin: 0;
  font-style: normal;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 22px;
}

.iconPositive {
  color: green;
}

.iconNegative {
  color: #ff4d4f;
}

h6 {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.57;
}

.blueColor {
  color: #3d69a6;
}

.red {
  background: rgba(255, 77, 78, 0.3);
}

.right {
  text-align: right;

  button {
    margin-top: 3px;
  }
}

.breakLine {
  white-space: pre-line;
}
