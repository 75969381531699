@import '../../styles/grid';
.logoWrapper {
  display: inline-block;
  width: 100%;
  background-color: #3d69a6;
}

.logo {
  margin: 10px 0 40px 20px;
}

.logoMini {
  display: none;
  margin: 15px auto 10px auto;
  width: 30px;

  @media screen and (min-width: $lg) {
    display: block;
  }
}

.ant-layout-sider-collapsed .logoWrapper {
  height: 60px;
}
