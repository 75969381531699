@import '../../styles/grid';
.informMessage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 90px auto 0 auto;
  width: 100%;

  @media screen and (min-width: $md) {
    width: 70%;
  }

  @media screen and (min-width: $lg) {
    width: 60%;
  }
}
